<template>
  <b-overlay :show="loading">
    <b-row class="pt-1">
      <b-col cols="3">
        <b-row>
          <b-col cols="12">
            <b-card
              v-if="showActions"
              :header="headerActions"
            >
              <div style="padding-top: 1rem">
                <b-row align-h="between">
                  <b-col cols="6">
                    <div slot="actions-left" />
                  </b-col>
                  <b-col
                    cols="6"
                    class="d-flex justify-content-end"
                  >
                    <div slot="actions-right" />
                  </b-col>
                </b-row>
              </div>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-card :header="folderHeader">
              <div style="padding-top: 1rem">
                <liquor-tree
                  ref="tree"
                  :data="folders"
                >
                  <slot />
                </liquor-tree>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="9">
        <slot name="folderSelected" />
      </b-col>
    </b-row>

  </b-overlay>
</template>

<script>
import LiquorTree from 'liquor-tree'

export default {
  name: 'CsTree',
  components: {
    LiquorTree,
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    showActions: {
      type: Boolean,
      required: false,
      default: true,
    },
    headerActions: {
      type: String,
      required: false,
      default: null,
    },
    folderHeader: {
      type: String,
      required: false,
      default: null,
    },
    folders: {
      type: Array,
      required: true,
    },
  },
}

</script>
