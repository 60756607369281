import Vue from 'vue'

import {
  extend, localize, setInteractionMode, ValidationProvider, ValidationObserver,
} from 'vee-validate'
import it from 'vee-validate/dist/locale/it.json'
import * as Rules from 'vee-validate/dist/rules'

// VeeValidate
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

// eslint-disable-next-line guard-for-in,no-restricted-syntax
for (const rule in Rules) {
  extend(rule, Rules[rule])
}

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target
  },
  message: 'Le password non corrispondono.',
})

localize({ it })
localize('it')

setInteractionMode('aggressive')
