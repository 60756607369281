<template>
  <b-button
    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
    type="submit"
    variant="primary"
    style="width: 100%"
    @click.prevent="click"
  >
    <slot />
  </b-button>
</template>

<script>
import Ripple from 'vue-ripple-directive'

export default {
  name: 'CsSubmitButton',
  directives: {
    Ripple,
  },
  props: {
    click: {
      type: Function,
      required: true,
    },
  },
}
</script>
