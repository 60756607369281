<template>

  <b-dropdown
    v-if="show"
    variant="link"
    toggle-class="text-decoration-none"
    no-caret
  >
    <template v-slot:button-content>
      <feather-icon
        icon="MoreVerticalIcon"
        size="16"
        class="text-body align-middle mr-25"
      />
    </template>

    <slot />
  </b-dropdown>

</template>
<script>
export default {
  name: 'CsTableActionColumn',
  props: {
    show: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
}
</script>
