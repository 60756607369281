import auth from '@/@custom/libs/auth'

export default {
  namespaced: true,
  state: {
    isUserLoggedIn: () => localStorage.getItem('userInfo') !== null
        && JSON.parse(localStorage.getItem('userInfo')).access_token,
    AppActiveUser: JSON.parse(localStorage.getItem('userInfo')),
  },
  getters: {
    getParams: state => key => state.params.get(key),
  },
  mutations: {
    UPDATE_USER_INFO(state, payload) {
      if (payload === null) {
        localStorage.removeItem('userInfo')
        state.AppActiveUser = null
        return
      }

      state.AppActiveUser = payload
      localStorage.setItem('userInfo', JSON.stringify(payload))
    },
  },
  actions: {
    login() {
      return new Promise((resolve, reject) => {
        auth.login().then(_ => {
          resolve(_)
        }).catch(error => {
          reject(error)
        })
      })
    },

    logout({ commit }) {
      return new Promise((resolve, reject) => {
        commit('auth/UPDATE_USER_INFO', null, { root: true })

        auth.logout().then(_ => {
          resolve(_)
        }).catch(error => {
          reject(error)
        })
      })
    },

    getUser({ commit }) {
      return new Promise((resolve, reject) => {
        auth.getUser().then(userData => {
          commit('auth/UPDATE_USER_INFO', userData, { root: true })
          resolve(userData)
        }).catch(error => {
          reject(error)
        })
      })
    },
  },
}
