<template>
  <div style="display: inline-block;">

    <b-button
      variant="primary"
      :title="title"
      @click="$bvModal.show(id)"
    >
      <feather-icon :icon="icon" />
    </b-button>

    <b-modal
      :id="id"
      no-close-on-backdrop
      :title="title"
      hide-footer
      size="lg"
    >
      <b-overlay :show="loading">
        <slot />
      </b-overlay>
    </b-modal>
  </div>

</template>

<script>

export default {
  name: 'CsButtonModal',
  props: {
    id: {
      type: String,
      required: false,
      default: 'modal',
    },
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>
