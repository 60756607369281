import axios from '@axios'

export default {
  single_byOpera: id => axios.get(`/api/v1/livello/${id}`),
  create(data) {
    const formData = new FormData()
    formData.append('file', data.file)
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
    return axios.post('/api/v1/livello', formData, config)
  },
  pdf_byOpera: id => axios.get(`/api/v1/livello/${id}/pdf`, { responseType: 'blob' }),
}
