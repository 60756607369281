import axios from '@axios'

export default {
  all: () => axios.get('/api/v1/opere'),
  single: id => axios.get(`/api/v1/opere/${id}`),
  create(data) {
    const formData = new FormData()
    formData.append('excel', data.excel)
    Object.keys(data.opera).forEach(key => {
      if (key.toString() !== 'id') formData.append(`opera.${key}`, data.opera[key])
    })
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
    return axios.post('/api/v1/opere', formData, config)
  },
  update: data => axios.put('/api/v1/opere', data),
  delete: id => axios.delete(`/api/v1/opere/${id}`),
  single_anagrafica: id => axios.get(`/api/v1/opere/${id}/anagrafica`),
  documento_remove: (id, fileId) => axios.delete(`/api/v1/opere/${id}/documenti/${fileId}`),
  documento_download: (id, fileId) => axios.get(`/api/v1/opere/${id}/documenti/${fileId}`, { responseType: 'blob' }),
  parziale_single: (id, parzialeId) => axios.get(`/api/v1/opere/${id}/parziali/${parzialeId}`),
  parte_single: (id, parzialeId, parteId) => axios.get(`/api/v1/opere/${id}/parziali/${parzialeId}/parti/${parteId}`),
  parte_add: (id, parzialeId, parte) => axios.post(`/api/v1/opere/${id}/parziali/${parzialeId}/parti`, parte),
  parte_remove: (id, parzialeId, parteId) => axios.delete(`/api/v1/opere/${id}/parziali/${parzialeId}/parti/${parteId}`),
  componenti_all: id => axios.get(`/api/v1/opere/${id}/parziali/parti/componenti/`),
  componente_add: (id, parzialeId, parteId, componente) => axios.post(`/api/v1/opere/${id}/parziali/${parzialeId}/parti/${parteId}/componenti`, componente),
  componente_update: (id, data) => axios.put(`/api/v1/opere/${id}/parziali/parti/componenti`, data),
  componente_remove: (id, parzialeId, parteId, componenteId) => axios.delete(`/api/v1/opere/${id}/parziali/${parzialeId}/parti/${parteId}/componenti/${componenteId}`),
  ispezioni_all: id => axios.get(`/api/v1/opere/${id}/ispezioni`),
  bim_load(id, file) {
    const formData = new FormData()
    formData.append('file', file)
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
    return axios.post(`/api/v1/opere/${id}/bim`, formData, config)
  },
  download_anagrafica: id => axios.get(`/api/v1/opere/${id}/pdf`, { responseType: 'blob' }),
}
