import router from '@/routing/router/index'

const ROUTING = Object.freeze({
  ERRORE404: 'error-404',
  ERRORE401: 'error-401',
  CAMBIA_PASSWORD: 'identity-cambia-password',
  IMPOSTAZIONI: 'identity-impostazioni',
  DASHBOARD: 'dashboard',
  GRUPPI_ELENCO: 'gruppi-elenco',
  GRUPPI_AGGIUNGI: 'gruppi-aggiungi',
  GRUPPI_UTENTI_ELENCO: 'gruppi-utenti-elenco',
  UTENTI_ELENCO: 'utenti-elenco',
  UTENTI_PERMESSI: 'utenti-permessi',
  UTENTI_RUOLI: 'utenti-ruoli',
  UTENTI_AGGIUNGI: 'utenti-aggiungi',
  OPERE_ELENCO: 'opere-elenco',
  OPERE_AGGIUNGI: 'opere-aggiungi',
  OPERE_DETTAGLIO: 'opere-dettaglio',
  OPERE_PARZIALI_DETTAGLIO: 'opere-parziali-dettaglio',
  OPERE_PARTI_DETTAGLIO: 'opere-parti-dettaglio',
  ISPEZIONI_DA_COMPILARE: 'ispezioni-elenco-compilare',
  ISPEZIONI_COMPILATE: 'ispezioni-elenco-compilate',
  ISPEZIONI_VALIDATE: 'ispezioni-elenco-validate',
  ISPEZIONI_APPROVATE: 'ispezioni-elenco-approvate',
  ISPEZIONI_ELENCO: 'ispezioni-elenco',
  ISPEZIONI_AGGIUNGI: 'ispezioni-aggiungi',
  ISPEZIONI_DETTAGLIO: 'ispezioni-dettaglio',
  ISPEZIONI_DETTAGLIO_SCHEDA: 'ispezioni-dettaglio-scheda',
  ISPEZIONI_OPERA: 'opere-ispezioni-elenco',
  LV_ELENCO: 'lv-elenco',
  LV_AGGIUNGI: 'lv-aggiungi',
  LV_DETTAGLIO: 'lv-dettaglio',
})

// eslint-disable-next-line no-extend-native
Object.defineProperty(String.prototype, 'push', {
  value: function push(params) {
    const name = this
    return router.push({ name, params })
  },
  writable: true,
  configurable: true,
})
export const routing = ROUTING
export const _ = null
