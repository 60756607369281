export default {
  namespaced: true,
  state: {
    columns: [],
    filters: [],
  },
  getters: {
    getColumns: state => key => state.columns.filter(f => f.key === key)[0]?.value, // state.columns.get(key),
    getFilters: state => key => state.filters.filter(f => f.key === key)[0]?.value,
  },
  mutations: {
    ADD_COLUMN(state, payload) {
      if (state.columns.filter(f => f.key === payload.key).length > 0) {
        const index = state.columns.map(m => m.key).indexOf(payload.key)
        state.columns.splice(index, 1)
      }
      state.columns.push(payload)
    },
    ADD_FILTER(state, payload) {
      if (state.filters.filter(f => f.key === payload.key).length > 0) {
        const index = state.filters.map(m => m.key).indexOf(payload.key)
        state.filters.splice(index, 1)
      }
      state.filters.push(payload)
    },
  },
  actions: {},
}
