import PERMESSI from '@/models/permessoEnum'
import RUOLI from '@/models/ruoloEnum'

export default [
  {
    path: '/ispezioni/elenco/incompilazione',
    name: 'ispezioni-elenco-compilare',
    component: () => import('@/views/ispezioni/elenco/InCompilazione.vue'),
    meta: {
      auth: true,
      action: PERMESSI.ISPEZIONI_VISUALIZZA,
      pageTitle: 'Ispezioni',
      breadcrumb: [
        {
          text: 'Ispezioni',
          active: false,
          to: '/ispezioni/elenco/incompilazione',
        },
        {
          text: 'Da Compilare',
          active: true,
        },
      ],
    },
  },
  {
    path: '/ispezioni/elenco/compilate',
    name: 'ispezioni-elenco-compilate',
    component: () => import('@/views/ispezioni/elenco/Compilate.vue'),
    meta: {
      auth: true,
      action: PERMESSI.ISPEZIONI_VISUALIZZA,
      pageTitle: 'Ispezioni',
      breadcrumb: [
        {
          text: 'Ispezioni',
          active: false,
          to: '/ispezioni/elenco/compilate',
        },
        {
          text: 'Compilate',
          active: true,
        },
      ],
    },
  },
  {
    path: '/ispezioni/elenco/validate',
    name: 'ispezioni-elenco-validate',
    component: () => import('@/views/ispezioni/elenco/Validate.vue'),
    meta: {
      auth: true,
      action: PERMESSI.ISPEZIONI_VISUALIZZA,
      pageTitle: 'Ispezioni',
      breadcrumb: [
        {
          text: 'Ispezioni',
          active: false,
          to: '/ispezioni/elenco/validate',
        },
        {
          text: 'Validate',
          active: true,
        },
      ],
    },
  },
  {
    path: '/ispezioni/elenco/approvate',
    name: 'ispezioni-elenco-approvate',
    component: () => import('@/views/ispezioni/elenco/Approvate.vue'),
    meta: {
      auth: true,
      action: PERMESSI.ISPEZIONI_VISUALIZZA,
      subject: RUOLI.CONCESSIONARIO,
      pageTitle: 'Ispezioni',
      breadcrumb: [
        {
          text: 'Ispezioni',
          active: false,
          to: '/ispezioni/elenco/approvate',
        },
        {
          text: 'Approvate',
          active: true,
        },
      ],
    },
  },
  {
    path: '/ispezioni',
    name: 'ispezioni-elenco',
    component: () => import('@/views/ispezioni/elenco/Elenco.vue'),
    meta: {
      auth: true,
      action: PERMESSI.ISPEZIONI_VISUALIZZA,
      pageTitle: 'Ispezioni',
      breadcrumb: [
        {
          text: 'Ispezioni',
          active: false,
          to: '/ispezioni',
        },
        {
          text: 'Elenco',
          active: true,
        },
      ],
    },
  },
  {
    path: '/ispezioni/aggiungi',
    name: 'ispezioni-aggiungi',
    component: () => import('@/views/ispezioni/Aggiungi.vue'),
    meta: {
      auth: true,
      action: PERMESSI.ISPEZIONI_AGGIUNGI,
      pageTitle: 'Ispezioni',
      breadcrumb: [
        {
          text: 'Ispezioni',
          active: false,
          to: '/ispezioni',
        },
        {
          text: 'Aggiungi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/ispezioni/:id',
    name: 'ispezioni-dettaglio',
    component: () => import('@/views/ispezioni/dettaglio/Tabs.vue'),
    meta: {
      auth: true,
      action: PERMESSI.ISPEZIONI_VISUALIZZA_DETTAGLIO,
      pageTitle: 'Ispezioni',
      back: {
        to: '/ispezioni',
      },
      buttons: [
        {
          key: 'opere-ispezioni-elenco',
          to: '/opere/:operaId/ispezioni',
          title: 'Ispezioni Opera',
          icon: 'FileTextIcon',
        },
      ],
      breadcrumb: [
        {
          text: 'Ispezioni',
          active: false,
          to: '/ispezioni',
        },
        {
          text: ':operaNome',
          to: '/opere/:operaId',
          active: false,
        },
        {
          prefix: 'Ispezione del ',
          text: ':data',
          active: true,
        },
      ],
    },
  },
  {
    path: '/ispezioni/:id/schede/:schedaId',
    name: 'ispezioni-dettaglio-scheda',
    component: () => import('@/views/ispezioni/dettaglio/schede/dettaglio/Tabs.vue'),
    meta: {
      auth: true,
      action: PERMESSI.ISPEZIONI_VISUALIZZA_DETTAGLIO,
      pageTitle: 'Ispezioni',
      back: {
        to: '/ispezioni/:id',
      },
      buttons: [
        {
          key: 'opere-ispezioni-elenco',
          to: '/opere/:operaId/ispezioni',
          title: 'Ispezioni Opera',
          icon: 'FileTextIcon',
        },
      ],
      breadcrumb: [
        {
          text: 'Ispezioni',
          active: false,
          to: '/ispezioni',
        },
        {
          text: ':operaNome',
          to: '/opere/:operaId',
          active: false,
        },
        {
          prefix: 'Ispezione del ',
          text: ':data',
          active: false,
          to: '/ispezioni/:id',
        },
        {
          prefix: 'Componente ',
          text: ':componente',
          active: true,
        },
      ],
    },
  },
  {
    path: '/ispezioni/:anno/:codice',
    name: 'ispezioni-dettaglio-scheda-da-bim',
    component: () => import('@/views/ispezioni/dettaglio/schede/dettaglio/Tabs.vue'),
    meta: {
      auth: true,
      action: PERMESSI.ISPEZIONI_VISUALIZZA_DETTAGLIO,
      pageTitle: 'Ispezioni',
      back: {
        to: '/ispezioni/:id',
      },
      buttons: [
        {
          key: 'opere-ispezioni-elenco',
          to: '/opere/:operaId/ispezioni',
          title: 'Ispezioni Opera',
          icon: 'FileTextIcon',
        },
      ],
      breadcrumb: [
        {
          text: 'Ispezioni',
          active: false,
          to: '/ispezioni',
        },
        {
          text: ':operaNome',
          to: '/opere/:operaId',
          active: false,
        },
        {
          prefix: 'Ispezione del ',
          text: ':data',
          active: false,
          to: '/ispezioni/:id',
        },
        {
          prefix: 'Componente ',
          text: ':componente',
          active: true,
        },
      ],
    },
  },
  {
    path: '/opere/:operaId/ispezioni',
    name: 'opere-ispezioni-elenco',
    component: () => import('@/views/ispezioni/elenco/Elenco.vue'),
    meta: {
      auth: true,
      action: PERMESSI.ISPEZIONI_VISUALIZZA,
      pageTitle: 'Ispezioni',
      breadcrumb: [
        {
          text: 'Opere',
          active: false,
          to: '/opere',
        },
        {
          text: ':nome',
          active: false,
          to: '/opere/:operaId',
        },
        {
          text: 'Ispezioni',
          active: true,
        },
      ],
    },
  },
]
