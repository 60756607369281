import PERMESSI from '@/models/permessoEnum'

export default [
  {
    path: '/lv',
    name: 'lv-elenco',
    component: () => import('@/views/lv/Elenco.vue'),
    meta: {
      auth: true,
      action: PERMESSI.LIVELLO2_VISUALIZZA,
      pageTitle: 'Livello 2',
      breadcrumb: [
        {
          text: 'Livello 2',
          active: false,
          to: '/lv',
        },
        {
          text: 'Elenco',
          active: true,
        },
      ],
    },
  },
  {
    path: '/lv/aggiungi',
    name: 'lv-aggiungi',
    component: () => import('@/views/lv/Aggiungi.vue'),
    meta: {
      auth: true,
      action: PERMESSI.LIVELLO2_AGGIUNGI,
      pageTitle: 'Opere',
      breadcrumb: [
        {
          text: 'Livello 2',
          active: false,
          to: '/lv',
        },
        {
          text: 'Aggiungi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/lv/:id',
    name: 'lv-dettaglio',
    component: () => import('@/views/lv/Dettaglio.vue'),
    meta: {
      auth: true,
      action: PERMESSI.LIVELLO2_VISUALIZZA_DETTAGLIO,
      pageTitle: 'Livello 2',
      back: {
        to: '/lv',
      },
      breadcrumb: [
        {
          text: 'Livello 2',
          active: false,
          to: '/lv',
        },
        {
          text: ':nome',
          active: true,
        },
      ],
    },
  },
]
