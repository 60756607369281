<template>
  <div>
    <label :for="id + '-input'">{{ label }}</label>
    <b-input-group>
      <b-form-input
        :id="id + '-input'"
        v-model="inputVal"
        type="text"
        placeholder="yyyy-MM-dd"
        autocomplete="off"
      />
      <b-input-group-append>
        <b-form-datepicker
          :id="id"
          v-model="inputVal"
          button-only
          right
          :readonly="readOnly"
          :disabled="disabled"
          :state="state"
          show-decade-nav
          label-no-date-selected="Nessuna data selezionata"
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          label-next-year="Anno successivo"
          label-next-month="Mese successivo"
          label-next-decade="Decade successiva"
          label-prev-year="Anno precedente"
          label-prev-month="Mese precedente"
          label-prev-decade="Decade precedente"
          label-current-month="Mese corrente"
          label-help="Usa le frecce per navigare."
          today-button
          label-today="Oggi"
          label-today-button="Seleziona oggi"
          reset-button
          locale="it-IT"
          :aria-controls="id + '-input'"
        />
      </b-input-group-append>
    </b-input-group>
  </div>
</template>

<script>
export default {
  name: 'CsDatePicker',
  props: {
    // eslint-disable-next-line vue/require-default-prop,vue/require-prop-types
    value: {},
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    state: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
}
</script>

<style>
.b-form-datepicker > .btn {
  padding: 9px;
  /*font-size: 170%;*/
}
</style>
