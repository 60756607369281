import Vue from 'vue'

Vue.mixin({
  methods: {
    setBreadcrumbData(store, key, value) {
      store.commit('breadcrumb/ADD_PARAMS', { key, value })
    },
    setFilterData(store, key, value) {
      store.commit('tables/ADD_FILTER', { key, value })
    },
    setColumnData(store, key, value) {
      store.commit('tables/ADD_COLUMN', { key, value })
    },
  },
})
