import Vue from 'vue'

import './@core/main'
import './@custom/main'

import './libs/vee-validate'
import './libs/acl'

import '@axios'
import './requests/index'

// import i18n from './@vendor/libs/i18n'
import router from './routing/router'
import store from './store'
import App from './App.vue'

import { routing } from '@/routing/router/utils'
import permessi from './models/permessoEnum'

require('./assets/scss/style.scss')

Vue.config.productionTip = false

Vue.prototype.$grants = permessi
Vue.prototype.$routing = routing

new Vue({
  router,
  store,
  // i18n,
  render: h => h(App),
}).$mount('#app')
