import axios from '@axios'

export default {
  all: () => axios.get('/api/v1/utenti'),
  auth: () => axios.get('/api/v1/utenti/auth'),
  single: id => axios.get(`/api/v1/utenti/${id}`),
  create: data => axios.post('/api/v1/utenti', data),
  delete: id => axios.delete(`/api/v1/utenti/${id}`),
  password_change: data => axios.put('/api/v1/utenti/password', data),
  permesso_remove: (id, permessoId) => axios.delete(`/api/v1/utenti/${id}/permessi/${permessoId}`),
  permesso_add: (id, permessoId) => axios.post(`/api/v1/utenti/${id}/permessi/${permessoId}`),
  ruolo_remove: (id, ruoloId) => axios.delete(`/api/v1/utenti/${id}/ruoli/${ruoloId}`),
  ruolo_add: (id, ruoloId) => axios.post(`/api/v1/utenti/${id}/ruoli/${ruoloId}`),
}
