import Oidc from 'oidc-client'
import { $themeConfig } from '@themeConfig'

const mgr = new Oidc.UserManager($themeConfig.authentication)

const userChangedSubscribers = []
const tokenExpiredSubscribers = []

mgr.events.addAccessTokenExpired(ev => {
  tokenExpiredSubscribers.filter(callback => callback(ev))
})

mgr.events.addUserLoaded(user => {
  userChangedSubscribers.filter(callback => callback(user))
})

export default {
  login() {
    return mgr.signinRedirect()
  },
  logout() {
    return mgr.signoutRedirect()
  },
  getUser() {
    return mgr.getUser()
  },
  signinRedirectCallback() {
    return mgr.signinRedirectCallback()
  },
  signoutRedirectCallback() {
    return mgr.signoutRedirectCallback()
  },
  signinSilentCallback() {
    return mgr.signinSilentCallback()
  },
  userChanged(callback) {
    userChangedSubscribers.push(callback)
  },
  tokenExpired(callback) {
    tokenExpiredSubscribers.push(callback)
  },
}
