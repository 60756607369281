import Vue from 'vue'

import utenti from './utenti'
import gruppi from './gruppi'
import opere from './opere'
import ispezioni from './ispezioni'
import values from './values'
import schede from './schede'
import dashboard from './dashboard'
import livello from './livello'

const requests = {
  utenti,
  gruppi,
  opere,
  ispezioni,
  values,
  schede,
  dashboard,
  livello,
}

Vue.prototype.$remote = requests

export default requests
