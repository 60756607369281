import Vue from 'vue'

Vue.mixin({
  methods: {
    showModal(id) {
      if (id !== null && id !== undefined) this.$bvModal.show(id)
    },
    hideModal(id) {
      if (id !== null && id !== undefined) this.$bvModal.hide(id)
    },
    showErrorMessage(message) {
      this.$bvToast.toast(message, {
        title: 'Errore',
        variant: 'danger',
        solid: true,
      })
    },
    showSuccessMessage(message) {
      this.$bvToast.toast(message, {
        title: 'Successo',
        variant: 'success',
        solid: true,
      })
    },
    showDeleteSuccessMessage() {
      this.showSuccessMessage('Eliminazione avvenuta con successo.')
    },
    showAddSuccessMessage() {
      this.showSuccessMessage('Aggiunta avvenuta con successo.')
    },
    showUpdateSuccessMessage() {
      this.showSuccessMessage('Aggiornamento avvenuto con successo.')
    },
    showDeleteSwal() {
      return this.$swal({
        title: 'Sei sicuro?',
        text: 'L\'operazione sarà irreversibile!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Elimina!',
        cancelButtonText: 'Annulla',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
    },
    showAttentionSwal() {
      return this.$swal({
        title: 'Sei sicuro?',
        text: 'L\'operazione sarà irreversibile!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Procedi!',
        cancelButtonText: 'Annulla',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
    },
    showWarningSwal(text) {
      return this.$swal({
        title: 'Attenzione',
        text,
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Ok!',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    showReposonseMessage(res) {
      // console.log(res.response.data)

      const error = res.response.data

      if (this.isNullOrUndefined(error)) {
        this.showErrorMessage('Errore generico')
        return
      }

      const hasDetails = error.HasDetails
      if (hasDetails) {
        const obj = Object.values(error.Data)[0]
        if (this.isNotNullOrUndefined(obj)) {
          const message = obj.Message
          const param = obj.Param
          let text = ''
          if (this.isNotNullOrUndefined(message)) text = message
          if (this.isNotNullOrUndefined(param)) text += ` ${param}`
          this.showErrorMessage(text)
          return
        }
      }

      const inner = error.InnerException
      if (this.isNotNullOrUndefined(inner)) {
        if (this.isNotNullOrUndefined(inner.InnerException)) {
          const message = inner.InnerException.Message
          if (this.isNotNullOrUndefined(message)) {
            this.showErrorMessage(message)
            return
          }
        } else {
          const message = inner.Message
          if (this.isNotNullOrUndefined(message)) {
            this.showErrorMessage(message)
            return
          }
        }
      }

      this.showErrorMessage('Errore generico')
    },
  },
})
