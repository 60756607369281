<template>
  <b-form-group
    :label="label"
    :label-for="id"
    :state="state"
  >
    <v-select
      :id="id"
      v-model="inputVal"
      :options="options"
      :disabled="disabled"
      :multiple="multiple"
      :reduce="reduce"
      :create-option="createOption"
      :clearable="clearable"
      :taggable="taggable"
      :label="optionLabel"
      :close-on-select="closeOnSelect"
      :clear-search-on-select="clearSearchOnSelect"
      @change="change"
    >
      <span slot="no-options">Nessun elemento trovato</span>
    </v-select>
  </b-form-group>

</template>

<script>
import vSelect from 'vue-select'

export default {
  name: 'CsSelect',
  components: {
    vSelect,
  },
  props: {
    // eslint-disable-next-line vue/require-prop-types,vue/require-default-prop
    value: {},
    optionLabel: {
      type: String,
      required: false,
      default: 'label',
    },
    taggable: {
      type: Boolean,
      required: false,
      default: false,
    },
    clearable: {
      type: Boolean,
      required: false,
      default: true,
    },
    label: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    state: {
      type: Boolean,
      required: false,
      default: null,
    },
    options: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    closeOnSelect: {
      type: Boolean,
      required: false,
      default: true,
    },
    clearSearchOnSelect: {
      type: Boolean,
      required: false,
      default: true,
    },
    reduce: {
      type: Function,
      required: false,
      default: obj => obj,
    },
    createOption: {
      type: Function,
      required: false,
      default: obj => obj,
    },
    change: {
      type: Function,
      required: false,
      default: obj => obj,
    },
  },
  computed: {
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
}
</script>
