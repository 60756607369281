<template>
  <cs-table-action-item
    :row="row"
    icon="TrashIcon"
    value="Elimina"
    :show="show"
    @click="onClick"
  />
</template>

<script>
export default {
  name: 'CsTableActionItemDelete',
  props: {
    row: {
      type: Object,
      required: true,
    },
    show: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  methods: {
    onClick(params) {
      this.$emit('click', params)
    },
  },
}
</script>
