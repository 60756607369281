<template>
  <b-dropdown-item
    v-if="show"
    @click="onClick"
  >
    <feather-icon
      :icon="icon"
      class="mr-50"
    />
    <span>{{ value }}</span>
  </b-dropdown-item>
</template>

<script>
export default {
  name: 'CsTableActionItem',
  props: {
    icon: {
      type: String,
      required: false,
      default: '',
    },
    value: {
      type: String,
      required: false,
      default: '',
    },
    row: {
      type: Object,
      required: true,
    },
    show: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  methods: {
    onClick() {
      this.$emit('click', this.row)
    },
  },
}
</script>
