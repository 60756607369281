<template>
  <b-form-group
    :label="label"
    :label-for="id"
  >
    <b-form-input
      :id="id"
      v-model="inputVal"
      :placeholder="placeholder"
      :type="type"
      :readonly="readOnly"
      :state="state"
      :step="step"
    />
  </b-form-group>
</template>

<script>
export default {
  name: 'CsInput',
  props: {
    // eslint-disable-next-line vue/require-default-prop,vue/require-prop-types
    value: {},
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    state: {
      type: Boolean,
      required: false,
      default: null,
    },
    step: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
}
</script>
