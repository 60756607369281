<template>
  <div class="custom-search d-flex justify-content-end">
    <b-form-group>
      <div class="d-flex align-items-center">
        <b-form-input
          v-model="searchTerm"
          placeholder="Cerca..."
          type="text"
          class="d-inline-block"
        />
      </div>
    </b-form-group>
  </div>
</template>

<script>

export default {
  name: 'CsTableSearch',
  props: {
    value: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    searchTerm: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
}
</script>
