import PERMESSI from '@/models/permessoEnum'

export default [
  {
    path: '/utenti',
    name: 'utenti-elenco',
    component: () => import('@/views/utenti/Elenco.vue'),
    meta: {
      auth: true,
      action: PERMESSI.UTENTI_VISUALIZZA,
      pageTitle: 'Utenti',
      breadcrumb: [
        {
          text: 'Utenti',
          active: false,
          to: '/utenti',
        },
        {
          text: 'Elenco',
          active: true,
        },
      ],
    },
  },
  {
    path: '/utenti/:id/permessi',
    name: 'utenti-permessi',
    component: () => import('@/views/utenti/Permessi.vue'),
    meta: {
      auth: true,
      action: PERMESSI.UTENTI_VISUALIZZA_DETTAGLIO,
      pageTitle: 'Utenti',
      breadcrumb: [
        {
          text: 'Utenti',
          active: false,
          to: '/utenti',
        },
        {
          text: ':nominativo',
          active: true,
        },
      ],
    },
  },
  {
    path: '/utenti/:id/ruoli',
    name: 'utenti-ruoli',
    component: () => import('@/views/utenti/Ruoli.vue'),
    meta: {
      auth: true,
      action: PERMESSI.UTENTI_VISUALIZZA_DETTAGLIO,
      pageTitle: 'Utenti',
      breadcrumb: [
        {
          text: 'Utenti',
          active: false,
          to: '/utenti',
        },
        {
          text: ':nominativo',
          active: true,
        },
      ],
    },
  },
  {
    path: '/utenti/add',
    name: 'utenti-aggiungi',
    component: () => import('@/views/utenti/Aggiungi.vue'),
    meta: {
      auth: true,
      action: PERMESSI.UTENTI_AGGIUNGI,
      pageTitle: 'Utenti',
      breadcrumb: [
        {
          text: 'Utenti',
          active: false,
          to: '/utenti',
        },
        {
          text: 'Aggiungi',
          active: true,
        },
      ],
    },
  },
]
