export default {
  namespaced: true,
  state: {
    used: new Map(),
    params: new Map(),
  },
  getters: {
    getParams: state => key => state.params.get(key),
  },
  mutations: {
    ADD_PARAMS(state, payload) {
      if (state.params.has(payload.key)) state.params.delete(payload.key)
      state.params.set(payload.key, payload.value)

      if (state.used.has(payload.key)) state.used.delete(payload.key)
      state.used.set(payload.key, new Date())

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of state.used.entries()) {
        if (new Date(value.getTime() + 5 * 60000) < new Date()) state.used.delete(key)
      }
    },
  },
  actions: {},
}
