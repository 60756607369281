import Vue from 'vue'

Vue.mixin({
  methods: {
    deleteRow(rows, index) {
      if (!(rows instanceof Array)) return null
      if (!this.isInteger(index)) return null
      return rows.splice(index, 1)
    },
    addRowAt(rows, item, index) {
      if (!(rows instanceof Array)) return null
      if (!this.isInteger(index)) return null
      const oldLen = rows.length
      rows.splice(index, 0, item)
      return oldLen < rows.length
    },
    addRowAtStart(rows, item) {
      return this.addRowAt(rows, item, 0)
    },
    addRowAtEnd(rows, item) {
      if (!(rows instanceof Array)) return null
      return this.addRowAt(rows, item, rows.length)
    },
    isInteger(number) {
      return typeof (number) === 'number'
      && Number.isFinite(number)
      && Math.round(number) === number
    },
    isNotNullOrUndefined(obj) {
      return obj !== undefined && obj !== null
    },
    isNullOrUndefined(obj) {
      return !(obj !== undefined && obj !== null)
    },
    downloadFile(data, name) {
      const blob = new Blob([data])
      const link = document.createElement('a')
      link.download = name.replace(/[/\\?%*:|"<>]/g, '-')
      link.href = URL.createObjectURL(blob)
      link.click()
      URL.revokeObjectURL(link.href)
    },
    numberSort(a, b) {
      if (a === null && b === null) return 0
      if (a === null) return -1
      if (b === null) return 1
      if (a > b) return 1
      if (a < b) return -1
      return 0
    },
    presenceSort(a) {
      if (a === null) return 1
      return -1
    },
    groupBy(list, keyGetter, sKey) {
      const map = new Map()
      list.forEach(item => {
        const key = keyGetter(item)
        if (!sKey || key === sKey) {
          const collection = map.get(key)
          if (!collection) {
            map.set(key, [item])
          } else {
            collection.push(item)
          }
        }
      })
      return map
    },
  },
})
