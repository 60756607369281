<template>
  <b-form-group>
    <validation-provider
      #default="{ errors }"
      :rules="rules"
      :name="label"
    >

      <slot :errors="errors" />

      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
export default {
  name: 'CsValidation',
  props: {
    rules: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
}
</script>
