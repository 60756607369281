import PERMESSI from '@/models/permessoEnum'

export default [
  {
    path: '/opere',
    name: 'opere-elenco',
    component: () => import('@/views/opere/Elenco.vue'),
    meta: {
      auth: true,
      action: PERMESSI.OPERE_VISUALIZZA,
      pageTitle: 'Opere',
      breadcrumb: [
        {
          text: 'Opere',
          active: false,
          to: '/opere',
        },
        {
          text: 'Elenco',
          active: true,
        },
      ],
    },
  },
  {
    path: '/opere/aggiungi',
    name: 'opere-aggiungi',
    component: () => import('@/views/opere/Aggiungi.vue'),
    meta: {
      auth: true,
      action: PERMESSI.OPERE_AGGIUNGI,
      pageTitle: 'Opere',
      breadcrumb: [
        {
          text: 'Opere',
          active: false,
          to: '/opere',
        },
        {
          text: 'Aggiungi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/opere/:id',
    name: 'opere-dettaglio',
    component: () => import('@/views/opere/dettaglio/Tabs.vue'),
    meta: {
      auth: true,
      action: PERMESSI.OPERE_VISUALIZZA_DETTAGLIO,
      pageTitle: 'Opere',
      back: {
        to: '/opere',
      },
      breadcrumb: [
        {
          text: 'Opere',
          active: false,
          to: '/opere',
        },
        {
          text: ':nome',
          active: true,
        },
      ],
    },
  },
  {
    path: '/opere/:id/parziali/:operaParzialeId',
    name: 'opere-parziali-dettaglio',
    component: () => import('@/views/opere/dettaglio/parti/Elenco.vue'),
    props: true,
    meta: {
      auth: true,
      action: PERMESSI.OPERE_PARZAILI_VISUALIZZA,
      pageTitle: 'Opere',
      back: {
        to: '/opere/:id',
      },
      breadcrumb: [
        {
          text: 'Opere',
          active: false,
          to: '/opere',
        },
        {
          text: ':nome',
          active: false,
          to: '/opere/:id',
        },
        {
          text: ':numeroOperaParziale',
          active: true,
        },
      ],
    },
  },
  {
    path: '/opere/:id/parziali/:operaParzialeId/parti/:parteId',
    name: 'opere-parti-dettaglio',
    component: () => import('@/views/opere/dettaglio/componenti/Elenco.vue'),
    props: true,
    meta: {
      auth: true,
      action: PERMESSI.OPERE_PARTI_VISUALIZZA,
      pageTitle: 'Opere',
      back: {
        to: '/opere/:id/parziali/:operaParzialeId',
      },
      breadcrumb: [
        {
          text: 'Opere',
          active: false,
          to: '/opere',
        },
        {
          text: ':nome',
          active: false,
          to: '/opere/:id',
        },
        {
          text: ':numeroOperaParziale',
          active: false,
          to: '/opere/:id/parziali/:operaParzialeId',
        },
        {
          text: ':nomeParte',
          active: true,
        },
      ],
    },
  },
]
