import axios from '@axios'

export default {
  single: id => axios.get(`/api/v1/schede/${id}`),
  update: data => axios.put('/api/v1/schede', data),
  foto_all: id => axios.get(`/api/v1/schede/${id}/foto`),
  foto_add(id, data) {
    const formData = new FormData()
    formData.append('file', data.file)
    formData.append('descrizione', data.descrizione)
    formData.append('difettoId', (data.difetto !== null) ? data.difetto.id : '00000000-0000-0000-0000-000000000000')
    formData.append('hasDifetto', (data.difetto !== null).toString())
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
    return axios.post(`/api/v1/schede/${id}/foto`, formData, config)
  },
  foto_download: (id, fotoId) => axios.get(`/api/v1/schede/${id}/foto/${fotoId}`, { responseType: 'blob' }),
  foto_remove: (id, fotoId) => axios.delete(`/api/v1/schede/${id}/foto/${fotoId}`),
  diffetti_update: (id, data) => axios.put(`/api/v1/schede/${id}/difetti`, data),
  diffetti_update_copy: (id, data) => axios.put(`/api/v1/schede/${id}/copy`, data),
  valida: id => axios.put(`/api/v1/schede/${id}/valida`),
  invalida: id => axios.put(`/api/v1/schede/${id}/invalida`),
}
