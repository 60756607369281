import Vue from 'vue'

import {
  BootstrapVue, IconsPlugin, ToastPlugin, ModalPlugin,
} from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueClipboard from 'vue-clipboard2'
import PortalVue from 'portal-vue'
import VueSweetalert2 from 'vue-sweetalert2'
import VueTour from 'vue-tour'

import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'

import '../libs/toastification'
import '../libs/vue-select'

Vue.use(VueClipboard)
Vue.use(PortalVue)
Vue.use(VueSweetalert2)
Vue.use(VueTour)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VueCompositionAPI)

Vue.component(FeatherIcon.name, FeatherIcon)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
// require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')
