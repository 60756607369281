<template>
  <div>
    <span v-if="props.column.field === 'action'">
      <cs-table-action-column :show="showActions">
        <slot />
      </cs-table-action-column>
    </span>

    <span v-else>
      {{ props.formattedRow[props.column.field] }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'CsTableActionColumnDefault',
  props: {
    props: {
      type: Object,
      required: true,
    },
    showActions: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
}
</script>
