<template>
  <div>

    <b-dropdown-item @click="showModal(id)">
      <feather-icon
        :icon="icon"
        class="mr-50"
      />
      <span>{{ text }}</span>
    </b-dropdown-item>

    <b-modal
      :id="id"
      no-close-on-backdrop
      :title="title"
      hide-footer
      size="lg"
    >
      <b-overlay :show="loading">
        <slot />
      </b-overlay>
    </b-modal>
  </div>

</template>

<script>

export default {
  name: 'CsDropDownModal',
  props: {
    id: {
      type: String,
      required: false,
      default: 'modal',
    },
    icon: {
      type: String,
      required: false,
      default: '',
    },
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: false,
      default: '',
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>
