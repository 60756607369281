<template>
  <cs-button-modal
    :id="id"
    :title="title"
    icon="PlusIcon"
    :loading="loading"
  >
    <!-- Pass on all named slots -->
    <slot />

  </cs-button-modal>
</template>

<script>
export default {
  name: 'CsButtonModalAdd',
  props: {
    id: {
      type: String,
      required: false,
      default: 'modal',
    },
    title: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>
