import axios from '@axios'

export default {
  all: () => axios.get('/api/v1/gruppi'),
  single: id => axios.get(`/api/v1/gruppi/${id}`),
  create: data => axios.post('/api/v1/gruppi', data),
  delete: id => axios.delete(`/api/v1/gruppi/${id}`),
  utente_add: (id, userId) => axios.post(`/api/v1/gruppi/${id}/utenti/${userId}`),
  utente_remove: (id, userId) => axios.delete(`/api/v1/gruppi/${id}/utenti/${userId}`),
}
