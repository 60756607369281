import PERMESSI from '@/models/permessoEnum'

export default [
  {
    path: '/gruppi',
    name: 'gruppi-elenco',
    component: () => import('@/views/gruppi/Elenco.vue'),
    meta: {
      auth: true,
      action: 'auth',
      pageTitle: 'Gruppi',
      breadcrumb: [
        {
          text: 'Gruppi',
          active: false,
          to: '/gruppi',
        },
        {
          text: 'Elenco',
          active: true,
        },
      ],
    },
  },
  {
    path: '/gruppi/aggiungi',
    name: 'gruppi-aggiungi',
    component: () => import('@/views/gruppi/Aggiungi.vue'),
    meta: {
      auth: true,
      action: PERMESSI.GRUPPI_AGGIUNGI,
      pageTitle: 'Gruppi',
      breadcrumb: [
        {
          text: 'Gruppi',
          active: false,
          to: '/gruppi',
        },
        {
          text: 'Aggiungi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/gruppi/:id/utenti',
    name: 'gruppi-utenti-elenco',
    component: () => import('@/views/gruppi/Utenti_Elenco.vue'),
    props: true,
    meta: {
      auth: true,
      action: 'auth',
      pageTitle: 'Gruppi',
      back: {
        to: '/gruppi',
      },
      breadcrumb: [
        {
          text: 'Gruppi',
          active: false,
          to: '/gruppi',
        },
        {
          text: ':nome',
          active: true,
        },
      ],
    },
  },
]
