import Vue from 'vue'
import VueRouter from 'vue-router'

import identity from './routes/identity'
import utenti from './routes/utenti'
import opere from './routes/opere'
import gruppi from './routes/gruppi'
import ispezioni from './routes/ispezioni'
import dashboard from './routes/dashboard'
import pages from './routes/pages'
import lv from './routes/lv'

import store from '@/store'
import ability from '@/libs/acl/ability'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'dashboard' } },
    ...dashboard,
    ...identity,
    ...opere,
    ...utenti,
    ...gruppi,
    ...ispezioni,
    ...lv,
    ...pages,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  // console.log(to.meta.action)
  if (to.meta.action === 'anon') return next()
  if (store.state.auth.isUserLoggedIn()) {
    if (to.meta.action === 'auth') return next()

    if (Array.isArray(to.meta.action)) {
      if (Array.isArray(to.meta.subject)) {
        for (let i = 0; i < to.meta.action.length; i += 1) {
          for (let j = 0; j < to.meta.subject.length; j += 1) {
            if (ability.can(to.meta.action[i], to.meta.subject[j])) return next()
          }
        }
      } else {
        for (let i = 0; i < to.meta.action.length; i += 1) if (ability.can(to.meta.action[i], to.meta.subject)) return next()
      }
      router.push({ path: '/error-401', query: { to: to.path } })
    } else if (Array.isArray(to.meta.subject)) {
      for (let i = 0; i < to.meta.subject.length; i += 1) if (ability.can(to.meta.action, to.meta.subject[i])) return next()
    } else if (!ability.can(to.meta.action, to.meta.subject)) {
      router.push({ path: '/error-401', query: { to: to.path } })
    }
    return next()
  }
  if (to.meta.auth) router.push({ path: '/identity/welcome', query: { to: to.path } })
  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
