<template>
  <div>
    <b-dropdown
      class="hide-col-dropdown"
      variant="primary"
    >
      <template #button-content>
        <feather-icon
          icon="ColumnsIcon"
        />
      </template>
      <b-dropdown-item
        v-for="(column, index) in columns.filter(f => !(f.label === '' || f.field === 'action'))"
        :key="index"
        @click="toggleColumn(index)"
      >
        <b-checkbox
          :checked="!column.hidden"
        >
          {{ column.label }}
        </b-checkbox>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  name: 'CsTableHideColumn',
  props: {
    columns: {
      type: Array,
      required: true,
    },
  },
  created() {
    const hData = this.$store.getters['tables/getColumns'](this.$route.name)
    if (!this.isNotNullOrUndefined(hData)) return
    this.columns.filter(f => hData.includes(f.label)).forEach(c => {
      this.$set(c, 'hidden', true)
    })
  },
  methods: {
    toggleColumn(index) {
      this.$set(this.columns[index], 'hidden', !this.columns[index].hidden)
      this.setColumnData(this.$store, this.$route.name, this.columns.filter(f => f.hidden === true).map(m => m.label))
    },
  },
}
</script>

<style lang="scss" scoped>
.hide-col-dropdown::v-deep {
  ul {
    z-index: 99;
    left: -50% !important;
  }
}
</style>
