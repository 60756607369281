<template>
  <b-tab
    :active="active"
  >
    <template #title>
      {{ text }}
      <b-spinner
        small
        class="ml-1"
        style="margin-bottom: 3px"
        :hidden="!isLoading"
      />
    </template>
    <slot v-if="show" />
  </b-tab>
</template>

<script>
export default {
  name: 'CsTab',
  props: {
    text: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    show: {
      type: Boolean,
      required: false,
      default: true,
    },
    active: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>
