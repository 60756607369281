<template>
  <b-overlay :show="loading">
    <b-card :header="cardHeader">

      <!-- Top -->
      <b-row
        align-h="between"
        class="mt-1"
      >
        <b-col cols="6">
          <slot name="topLeft" />
        </b-col>
        <!-- input search -->
        <b-col
          class="d-flex justify-content-end"
          cols="6"
        >
          <slot name="topRightBefore" />
          <cs-table-search
            v-if="globalSearch"
            v-model="searchTerm"
            class="mr-1"
          />
          <cs-table-hide-column
            v-if="hideColumn"
            :columns="columns"
          />
          <slot name="topRight" />
        </b-col>
      </b-row>

      <cs-table
        :columns="columns"
        :rows="rows"
        :page-length="pageLength"
        :search-options="{
          enabled: globalSearch,
          externalQuery: searchTerm
        }"
        :sort-opt="sortOpt"
        :fixed-header="fixedHeader"
        :max-height="maxHeight"
        :store-filter="storeFilter"
        :row-style-class-fn="rowStyleClassFn"
      >
        <!-- Pass on all named slots -->
        <slot
          v-for="slot in Object.keys($slots)"
          :slot="slot"
          :name="slot"
        />

        <!-- Pass on all scoped slots -->
        <template
          v-for="slot in Object.keys($scopedSlots)"
          :slot="slot"
          slot-scope="scope"
        >
          <slot
            :name="slot"
            v-bind="scope"
          />
        </template>

      </cs-table>

    </b-card>
  </b-overlay>
</template>

<script>
export default {
  name: 'CsTableCard',
  props: {
    cardHeader: {
      type: String,
      required: true,
    },
    rows: {
      type: Array,
      required: false,
      default: () => [],
    },
    columns: {
      type: Array,
      required: true,
      default: () => [],
    },
    pageLength: {
      type: Number,
      required: false,
      default: 100,
    },
    sortOpt: {
      type: Object,
      required: false,
      default: () => {},
    },
    fixedHeader: {
      type: Boolean,
      required: false,
      default: false,
    },
    maxHeight: {
      type: String,
      required: false,
      default: '',
    },
    storeFilter: {
      type: Boolean,
      required: false,
      default: true,
    },
    hideColumn: {
      type: Boolean,
      required: false,
      default: true,
    },
    globalSearch: {
      type: Boolean,
      required: false,
      default: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    rowStyleClassFn: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      searchTerm: '',
    }
  },
}
</script>
