export default [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      action: 'anon',
      auth: false,
    },
  },
  {
    path: '/error-401',
    name: 'error-401',
    component: () => import('@/views/error/Error401.vue'),
    meta: {
      layout: 'full',
      action: 'anon',
      auth: false,
    },
  },
]
