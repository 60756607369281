<template>
  <b-overlay :show="loading">

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :search-options="searchOptions"
      :fixed-header="fixedHeader"
      :pagination-options="{
        enabled: true,
        perPage: pgLength
      }"
      :sort-options="sortOpt"
      :compact-mode="true"
      style-class="vgt-table striped"
      :max-height="maxHeight"
      :row-style-class="rowStyleClassFn"
      @on-column-filter="onColumnFilter"
    >
      <!-- No elements -->
      <div slot="emptystate">
        Nessun elemento da visualizzare
      </div>

      <!-- Pass on all named slots -->
      <slot
        v-for="slot in Object.keys($slots)"
        :slot="slot"
        :name="slot"
      />

      <!-- Pass on all scoped slots -->
      <template
        v-for="slot in Object.keys($scopedSlots)"
        :slot="slot"
        slot-scope="scope"
      >
        <slot
          :name="slot"
          v-bind="scope"
        />
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <b-form-select
              v-model="pgLength"
              :options="[20,50,100]"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pgLength"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

  </b-overlay>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'

export default {
  name: 'CsTable',
  components: {
    VueGoodTable,
  },
  props: {
    rows: {
      type: Array,
      required: false,
      default: () => [],
    },
    columns: {
      type: Array,
      required: true,
    },
    pageLength: {
      type: Number,
      required: false,
      default: 100,
    },
    searchOptions: {
      type: Object,
      required: false,
      default: () => {},
    },
    sortOpt: {
      type: Object,
      required: false,
      default: () => {},
    },
    fixedHeader: {
      type: Boolean,
      required: false,
      default: false,
    },
    maxHeight: {
      type: String,
      required: false,
      default: '',
    },
    storeFilter: {
      type: Boolean,
      required: false,
      default: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    rowStyleClassFn: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      pgLength: 100,
    }
  },
  created() {
    this.pgLength = this.pageLength === undefined || this.pageLength === null ? 100 : this.pageLength

    if (this.storeFilter) {
      const fData = this.$store.getters['tables/getFilters'](this.$route.path)
      if (!this.isNotNullOrUndefined(fData)) return
      this.columns.filter(f => Object.keys(fData).includes(f.field)).forEach(c => this.$set(c.filterOptions, 'filterValue', fData[c.field]))
    }
  },
  methods: {
    onColumnFilter(params) {
      if (this.storeFilter) this.setFilterData(this.$store, this.$route.path, params.columnFilters)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table';
</style>
