import Vue from 'vue'
import { abilitiesPlugin } from '@casl/vue'
import ability from './ability'

Vue.use(abilitiesPlugin, ability)

// eslint-disable-next-line no-extend-native
Object.defineProperty(Number.prototype, 'can', {
  get() { return ability.can(this) },
})

// eslint-disable-next-line no-extend-native
Object.defineProperty(String.prototype, 'can', {
  get() { return ability.can(this) },
})
