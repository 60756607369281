export default [
  {
    path: '/identity/welcome',
    name: 'page-welcome',
    component: () => import('@/views/identity/Welcome.vue'),
    meta: {
      layout: 'full',
      auth: false,
      action: 'anon',
    },
  },
  {
    path: '/identity/callback',
    name: 'page-callback',
    component: () => import('@/views/identity/Callback.vue'),
    meta: {
      layout: 'full',
      auth: false,
      action: 'anon',
    },
  },
  {
    path: '/identity/silent-renew',
    name: 'page-silent-renew',
    component: () => import('@/views/identity/SilentRenew.vue'),
    meta: {
      layout: 'full',
      auth: false,
      action: 'anon',
    },
  },
  {
    path: '/identity/cambia-password',
    name: 'identity-cambia-password',
    component: () => import('@/views/identity/CambiaPassword.vue'),
    meta: {
      auth: true,
      action: 'auth',
      pageTitle: 'Cambia Password',
      breadcrumb: [
        {
          text: 'Cambia Password',
          active: true,
        },
      ],
    },
  },
  {
    path: '/identity/impostazioni',
    name: 'identity-impostazioni',
    component: () => import('@/views/identity/Impostazioni.vue'),
    meta: {
      pageTitle: 'Impostazioni',
      action: 'auth',
      breadcrumb: [
        {
          text: 'Impostazioni',
          active: true,
        },
      ],
    },
  },
]
