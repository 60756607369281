const VALUES = Object.freeze({
  OPERE_VISUALIZZA: 101,
  OPERE_VISUALIZZA_DETTAGLIO: 102,

  OPERE_AGGIUNGI: 110,
  OPERE_ELIMINA: 111,

  OPERE_PARZAILI_VISUALIZZA: 121,

  OPERE_PARTI_AGGIUNGI: 130,
  OPERE_PARTI_ELIMINA: 131,
  OPERE_PARTI_VISUALIZZA: 132,

  OPERE_COMPONENTI_AGGIUNGI: 140,
  OPERE_COMPONENTI_ELIMINA: 141,
  OPERE_COMPONENTI_AGGIORNA: 142,
  OPERE_COMPONENTI_VISUALIZZA: 143,

  OPERE_DOCUMENTI_AGGIUNGI: 150,
  OPERE_DOCUMENTI_ELIMINA: 151,
  OPERE_DOCUMENTI_SCARICA: 152,

  OPERE_ANAGRAFICA_VISUALIZZA: 160,
  OPERE_ANAGRAFICA_MODIFICA: 161,

  OPERE_BIM_VISUALIZZA: 170,
  OPERE_BIM_CARICA: 171,

  ISPEZIONI_VISUALIZZA: 201,
  ISPEZIONI_VISUALIZZA_DETTAGLIO: 202,
  ISPEZIONI_AGGIUNGI: 203,

  ISPEZIONI_EDITABILI_AGGIORNA_DATA: 210,
  ISPEZIONI_EDITABILI_VALIDA: 211,

  ISPEZIONI_EDITABILI_UTENTI_AGGIUNGI: 220,
  ISPEZIONI_EDITABILI_UTENTI_ELIMINA: 221,

  ISPEZIONI_EDITABILI_SCHEDE_AGGIUNGI: 230,
  ISPEZIONI_EDITABILI_SCHEDE_ELIMINA: 231,
  ISPEZIONI_EDITABILI_SCHEDE_AGGIORNA: 232,

  ISPEZIONI_EDITABILI_SCHEDE_AGGIORNA_NOTE_GENERALI: 240,

  ISPEZIONI_EDITABILI_SCHEDE_AGGIUNGI_FOTO: 250,
  ISPEZIONI_EDITABILI_SCHEDE_ELIMINA_FOTO: 251,

  ISPEZIONI_EDITABILI_SCHEDE_VALIDA: 260,
  ISPEZIONI_EDITABILI_SCHEDE_INVALIDA: 261,

  ISPEZIONI_VALIDATE_INVALIDA: 270,
  ISPEZIONI_VALIDATE_APPROVA: 271,

  ISPEZIONI_NON_APPROVATE_ELIMINA: 280,

  ISPEZIONI_SCHEDE_SCARICA_FOTO: 290,

  UTENTI_VISUALIZZA: 300,
  UTENTI_VISUALIZZA_DETTAGLIO: 301,

  UTENTI_AGGIUNGI: 310,
  UTENTI_ELIMINA: 311,

  UTENTI_AGGIORNA_PERMESSI: 320,
  UTENTI_AGGIORNA_RUOLI: 321,

  GRUPPI_VISUALIZZA: 400,
  GRUPPI_AGGIUNGI: 401,
  GRUPPI_ELIMINA: 402,
  GRUPPI_AGGIORNA: 403,

  LIVELLO2_VISUALIZZA: 501,
  LIVELLO2_VISUALIZZA_DETTAGLIO: 502,
  LIVELLO2_AGGIUNGI: 510,
})

export default VALUES
