import axios from '@axios'

export default {
  all: () => axios.get('/api/v1/ispezioni'),
  all_Compilare: () => axios.get('/api/v1/ispezioni/compilare'),
  all_Compilate: () => axios.get('/api/v1/ispezioni/compilate'),
  all_Validate: () => axios.get('/api/v1/ispezioni/validate'),
  all_Approvate: () => axios.get('/api/v1/ispezioni/approvate'),
  single: id => axios.get(`/api/v1/ispezioni/${id}`),
  create: data => axios.post('/api/v1/ispezioni', data),
  delete: id => axios.delete(`/api/v1/ispezioni/${id}`),
  update: data => axios.put('/api/v1/ispezioni', data),
  valida: (id, file) => {
    const formData = new FormData()
    formData.append('file', file)
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
    return axios.post(`/api/v1/ispezioni/${id}/valida`, formData, config)
  },
  approva: (id, file) => {
    const formData = new FormData()
    formData.append('file', file)
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
    return axios.post(`/api/v1/ispezioni/${id}/approva`, formData, config)
  },
  invalida: id => axios.put(`/api/v1/ispezioni/${id}/invalida`),
  utente_add: (id, userId) => axios.post(`/api/v1/ispezioni/${id}/utenti/${userId}`),
  utente_remove: (id, userId) => axios.delete(`/api/v1/ispezioni/${id}/utenti/${userId}`),
  schede_densita: id => axios.get(`/api/v1/ispezioni/${id}/schede/dr`),
  scheda_remove: (id, schedaId) => axios.delete(`/api/v1/ispezioni/${id}/schede/${schedaId}`),
  scheda_add: (id, componenteId) => axios.post(`/api/v1/ispezioni/${id}/schede/componenti/${componenteId}`),
  foto_all: id => axios.get(`/api/v1/ispezioni/${id}/foto`),
  foto_add(id, data) {
    const formData = new FormData()
    formData.append('file', data.file)
    formData.append('descrizione', data.descrizione)
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
    return axios.post(`/api/v1/ispezioni/${id}/foto`, formData, config)
  },
  foto_download: (id, fotoId) => axios.get(`/api/v1/ispezioni/${id}/foto/${fotoId}`, { responseType: 'blob' }),
  foto_remove: (id, fotoId) => axios.delete(`/api/v1/ispezioni/${id}/foto/${fotoId}`),
  sintesi_download: id => axios.get(`/api/v1/ispezioni/${id}/sintesi`, { responseType: 'blob' }),
  sintesischeda_download: id => axios.get(`/api/v1/ispezioni/${id}/sintesischeda`, { responseType: 'blob' }),
  dr_download: id => axios.get(`/api/v1/ispezioni/${id}/schede/dr/download`, { responseType: 'blob' }),
  pdf_download: id => axios.get(`/api/v1/ispezioni/${id}/schedeg4g5`, { responseType: 'blob' }),
  idFromBim: (anno, codice) => axios.get(`/api/v1/ispezioni/${anno}/componente/${codice}`),
}
