import Vue from 'vue'

import CsCheckBox from './cs-form/CsCheckBox.vue'
import CsDatePicker from './cs-form/CsDatePicker.vue'
import CsInput from './cs-form/CsInput.vue'
import CsSelect from './cs-form/CsSelect.vue'
import CsSubmitButton from './cs-form/CsSubmitButton.vue'

import CsButtonModal from './cs-modal/CsButtonModal.vue'
import CsButtonModalAdd from './cs-modal/impls/CsButtonModalAdd.vue'
import CsDropDownModal from './cs-modal/CsDropDownModal.vue'

import CsTableSearch from './cs-table/addons/CsTableSearch.vue'
import CsTableHideColumn from './cs-table/addons/CsTableHideColumn.vue'
import CsTable from './cs-table/CsTable.vue'
import CsFullTable from './cs-table/CsFullTable.vue'
import CsTableCard from './cs-table/CsTableCard.vue'
import CsTableActionColumn from './cs-table/components/actions/CsTableActionColumn.vue'
import CsTableActionColumnDefault from './cs-table/components/actions/impls/CsTableActionColumnDefault.vue'
import CsTableActionItem from './cs-table/components/actions/CsTableActionItem.vue'
import CsTableActionItemAdd from './cs-table/components/actions/impls/CsTableActionItemAdd.vue'
import CsTableActionItemDelete from './cs-table/components/actions/impls/CsTableActionItemDelete.vue'
import CsTableActionItemDetails from './cs-table/components/actions/impls/CsTableActionItemDetails.vue'
import CsTableActionItemDownload from './cs-table/components/actions/impls/CsTableActionItemDownload.vue'

import CsTab from './cs-tabs/CsTab.vue'

import CsTree from './cs-tree/CsTree.vue'

import CsValidation from './cs-validation/CsValidation.vue'

Vue.component(CsCheckBox.name, CsCheckBox)
Vue.component(CsDatePicker.name, CsDatePicker)
Vue.component(CsInput.name, CsInput)
Vue.component(CsSelect.name, CsSelect)
Vue.component(CsSubmitButton.name, CsSubmitButton)

Vue.component(CsButtonModal.name, CsButtonModal)
Vue.component(CsButtonModalAdd.name, CsButtonModalAdd)
Vue.component(CsDropDownModal.name, CsDropDownModal)

Vue.component(CsTableSearch.name, CsTableSearch)
Vue.component(CsTableHideColumn.name, CsTableHideColumn)
Vue.component(CsTable.name, CsTable)
Vue.component(CsFullTable.name, CsFullTable)
Vue.component(CsTableCard.name, CsTableCard)
Vue.component(CsTableActionColumn.name, CsTableActionColumn)
Vue.component(CsTableActionColumnDefault.name, CsTableActionColumnDefault)
Vue.component(CsTableActionItem.name, CsTableActionItem)
Vue.component(CsTableActionItemAdd.name, CsTableActionItemAdd)
Vue.component(CsTableActionItemDelete.name, CsTableActionItemDelete)
Vue.component(CsTableActionItemDetails.name, CsTableActionItemDetails)
Vue.component(CsTableActionItemDownload.name, CsTableActionItemDownload)

Vue.component(CsTab.name, CsTab)

Vue.component(CsTree.name, CsTree)

Vue.component(CsValidation.name, CsValidation)
