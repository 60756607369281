import RUOLI from '@/models/ruoloEnum'
import PERMESSI from '@/models/permessoEnum'

export function getUserRole() {
  const userData = JSON.parse(localStorage.getItem('userInfo'))

  let roles = []
  if (userData) roles = JSON.parse(userData.profile.roles)

  return roles.map(m => ({ subject: m.codice }))
}

export function getUserAbility() {
  const userData = JSON.parse(localStorage.getItem('userInfo'))

  let grants = []
  if (userData) grants = JSON.parse(userData.profile.grants)

  const existingAbility = grants.filter(f => f.codice !== PERMESSI.ISPEZIONI_VISUALIZZA).map(m => ({ action: m.codice }))
  existingAbility.splice(0, 0, ({ action: 'anon' }))
  if (userData) existingAbility.splice(0, 0, ({ action: 'auth' }))

  const isConcessionario = getUserRole().length === 1 && getUserRole().some(s => s.subject === RUOLI.CONCESSIONARIO)

  if (!isConcessionario) {
    if (grants.some(f => f.codice === PERMESSI.ISPEZIONI_VISUALIZZA)) {
      existingAbility.splice(0, 0, ({ action: PERMESSI.ISPEZIONI_VISUALIZZA }))
      existingAbility.splice(0, 0, ({ action: PERMESSI.ISPEZIONI_VISUALIZZA, subject: RUOLI.CONCESSIONARIO }))
    }
  } else {
    existingAbility.splice(0, 0, ({ action: PERMESSI.ISPEZIONI_VISUALIZZA, subject: RUOLI.CONCESSIONARIO }))
  }

  return existingAbility
}

export const _ = null
