<template>
  <b-form-group
    :label="inline ? '' : label"
    :label-for="id"
  >
    <b-form-checkbox
      :id="id"
      v-model="inputVal"
      :readonly="readOnly"
      :switch="isSwitch"
      :inline="inline"
      :state="state"
    >
      <span v-if="inline">
        {{ label }}
      </span>
    </b-form-checkbox>
  </b-form-group>
</template>

<script>
export default {
  name: 'CsCheckBox',
  props: {
    // eslint-disable-next-line vue/require-default-prop,vue/require-prop-types
    value: {},
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
      default: '',
    },
    inline: {
      type: Boolean,
      required: false,
      default: true,
    },
    isSwitch: {
      type: Boolean,
      required: false,
      default: false,
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    state: {
      type: Boolean,
      required: false,
      default: null,
    },
  },
  computed: {
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
}
</script>
