import Vue from 'vue'
import Vuex from 'vuex'

import createPersistedState from 'vuex-persistedstate'

// Modules
import app from './@core/store/app'
import appConfig from './@core/store/app-config'
import verticalMenu from './@core/store/vertical-menu'
import breadcrumb from './@custom/store/cs-breadcrumb'
import tables from './@custom/store/cs-tables'
import auth from './@custom/store/auth'

Vue.use(Vuex)

const dataState = createPersistedState({
  paths: ['tables.columns', 'tables.filters'],
})

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    breadcrumb,
    tables,
    auth,
  },
  plugins: [dataState],
  strict: process.env.DEV,
})
